@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

html * {
  font-family: 'Nunito', sans-serif;
  color: #212121;
}

html, body {
  background: #ffffff;
  margin: 0;
}

// all images will be fluid
img {
  max-width: 100%;
  height: auto;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
}

.privacy-terms {
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
  padding: 5%;

  p, ul {
    margin-top: 0;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 114px;
  border-radius: 22px;
  box-shadow: 0 9px 38px 0 rgba(86, 86, 86, 0.1);
  margin-bottom: 32px;
}

.brand-name {
  width: 260px;
  height: 43px;
  margin-bottom: 12px;
  object-fit: contain;
}

.tagline {
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 1.6;
  color: #3f3f3f;
  text-align: center;
}

.btn-row {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 166px;
  height: 54px;
  background-image: linear-gradient(109deg, #e53d68 -120%, #fba73b 160%);
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: none;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 124px;
  border-top: 1px solid #eaeaea;
  padding-top: 5px;

  &__email {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  &__links {
    font-size: 16px;
  }

  .copy {
    font-size: 14px;
    line-height: 3;
  }
}
